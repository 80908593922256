// version 1

export default function Customizer(){
    return(

        <div className="interface">
            <div className="title">
                <h1>Welcome to Customizer</h1>
                <div className="image"> 
                        <img src='https://www.modulo.co.in/img/logo/logowhite1.png' alt="logo" />
                    </div>
            </div>
            <div className="window">
                <div className="options">
                        <h2>Customize</h2>
                </div>
                <div className="options">
                    <h2>View</h2>
                </div>
            </div>
        </div>
    );
}
