export default function Post(){

    return(
      <div className="post">
       <div className="image">
          <img src = 'https://www.modulo.co.in/img/logo/logowhite1.png'  alt="this image lol" />
       </div>
          <div className="texts">
            <h2>Welcome</h2>
             <p className="info">
               <a className="author" >By ACUBIQ</a>
               <time>02-05-2024 thu</time>
             </p>
            <p className="summary">With this Customizer, you can create your custom furniture and view it in your room. Choose what fits the best before you buy!</p>
          </div>
     </div>
    );
}