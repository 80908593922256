import Component from "../ComponentTest";
import Customizer from "../Custoizer";

export default function TestingPage() {
    return(
        <div>
       
        <Customizer />
        </div>
    );

}