
import Post from "../Post";

export default function IndexPage() {
    return(
        <div>
        <Post />
       
       
        </div>
    );

}