import { Link } from "react-router-dom";
export default function Header(){
    return(

    <header>
        <Link to="/" className="logo">MODULO</Link>
        <nav>
            <Link to="/login" >Login</Link>   
            <Link to="/register" >Register</Link>       
            <Link to="/testpage">Customize</Link>     
        </nav>
    </header>
    

    );
}